<template>
  <header>
    <!-- 头部顶部 -->
    <div class="header_top" v-if="$route.name != '登录'">
      <div class="header_top_box">
        <div class="header_top_box_left">
          欢迎来到韬儒
          <span @click="routeTo('/login')" v-if="userInfo.token == undefined"
            >[请登录]</span
          >
          <span v-if="userInfo.token != undefined"  @click="routeTo('/personalCenter')">{{
            userInfo.userInfo.nickName
          }}</span>
          <span @click="signOut" v-if="userInfo.token != undefined"
            >,[退出]</span
          >
        </div>
        <div class="header_top_box_right">
          <ul>
            <li @click="routeTo('/shopCar')"><i class="iconfont icongouwuche1"></i>去购物车</li>
            <li @click="routeTo('/')">韬儒首页</li>
            <li @click="routeTo('/cemeteryNews')">陵园新闻</li>
            <li @click="routeTo('/about')">关于韬儒</li>
            <li @mouseover="mouseOver" @mouseleave="mouseLeave">
              <i class="iconfont iconshouji"></i>韬儒小程序
              <div v-show="this.show" class="code">
                <img src="@/assets/images/dia.jpg" alt="" />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="header_body" v-if="$route.name != '陵园首页' && $route.meta.parent != '陵园首页'&& $route.name != '店铺详情' && $route.meta.parent != '店铺详情'">
      <div class="header_body_box">
        <img @click="routeTo('/')" src="@/assets/images/logo.png" />
        <slot name="mens"></slot>
        <slot name="search"></slot>
        <div
          class="car"
          v-if="$route.name != '登录'"
          @mouseover="mouseOverCar"
          @mouseleave="mouseLeaveCar"
        >
          <i class="iconfont icongouwuche1"></i>
          <span class="title" @click="routeTo('/shopCar')">我的购物车</span>
          <Badge
            style="position: relative; z-index: 2"
            :count="count"
            overflow-count="9"
          ></Badge>
          <!-- //最近加入对的商品 -->
          <div class="latelyJoin" v-if="this.carShow">
            <div class="latelyJoinBox" v-if="shopLis.length < 1">
              <p class="latelyJoinBoxP">购物车暂无商品</p>
            </div>
            <div class="latelyJoinBox" v-else>
              <p class="latelyJoinTitle">最近加入的商品</p>
              <div
                class="latelyJoinBoxMain"
                v-for="(item, index) in shopLis"
                :key="index"
              >
                <div class="latelyJoinBoxMainOne">
                  <img :src="item.goodsPic" alt="" />
                  <div class="latelyJoinBoxMainOneD">{{ item.goodsName }}</div>
                </div>
                <div class="latelyJoinBoxMainTwo">
                  <p class="latelyJoinBoxMainTwoP1">
                    ¥{{ item.goodsDiscountPrice }}×{{ item.goodsCount }}
                  </p>
                  <p
                    class="latelyJoinBoxMainTwoP2"
                    @click="deClick(item.shoppingCartGoodsId)"
                  >
                    删除
                  </p>
                </div>
              </div>
              <div class="latelyJoinFooter">
                <span></span>
                <p @click="routeTo('/shopCar')">去购物车</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import api from "@/util/api";
import bus from "@/util/bus";
export default {
  data() {
    return {
      value11: "",
      count: 0,
      show: false,
      carShow: false,
      userInfo: JSON.parse(sessionStorage.getItem("userInfo") || "{}"),
      shopLis: [],
    };
  },
  mounted() {
    this.$nextTick(() => {
      if(this.userInfo.token) {
        console.log("初始化");
        this.shopList();
        this.getRound();
      }
    });
    
  },
  watch: {
    userInfo: {
      deep: true,
      handler: function (newV, oldV) {
        console.log("监听");
        !newV && this.$forceUpdate();
      },
    },
  },
  methods: {
    getRound() {
      let that = this;
      bus.$on("setRound", function (msg) {
        if(msg == 'success'){
          that.shopList();
        }
      });
    },
    mouseOver() {
      this.show = true;
    },
    mouseLeave() {
      this.show = false;
    },
    mouseOverCar() {
      this.carShow = true;
    },
    mouseLeaveCar() {
      this.carShow = false;
    },
    signOut() {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("userInfo");
      this.userInfo = undefined;
      this.$router.go(0);
      this.$Message.success("退出成功");
      // this.$router.push("/");
    },
    shopList() {
      let searchForm = { page: 1, pageSize: 10, search: {} };
      api.shoppingCartShoppingCartGoodsList(searchForm, (res) => {
        if (res.data.length > 2) {
          this.shopLis = res.data.slice(0, 2) || [];
        } else {
          this.shopLis = res.data || [];
        }
        this.count = res.data.length;
      });
    },
    deClick(id) {
      let shoppingCartGoodsIdList = [];
      shoppingCartGoodsIdList.push(id);
      api.shoppingCartDelShoppingCartGoods(
        { shoppingCartGoodsIdList },
        (res) => {
          this.$Message.success("删除成功");
          this.shopList();
        }
      );
    },
  },
};
</script>

<style lang="less" scoped>
header {
  width: 100%;
  font-family: PingFangSC-Medium, PingFang SC;
  color: #8d8d8d;
  background: #f8f8f8;
  .header_top {
    width: 100%;
    height: 32px;
    background: #ededee;
    .header_top_box {
      width: 1120px;
      height: 32px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: auto;
      .header_top_box_left {
        height: 17px;
        font-size: 12px;
        font-weight: 500;
        line-height: 17px;
        span {
          height: 16px;
          font-size: 12px;
          color: #e50000;
          line-height: 17px;
          cursor: pointer;
        }
      }
      .header_top_box_right {
        font-weight: 500;
        color: #8d8d8d;
        font-size: 12px;
        ul {
          list-style: none;
          li:nth-child(1):hover{
            color:red;
          }
          li:hover {
            color: #299654;
          }
          li {
            display: inline;
            line-height: 40px;
            float: left;
            margin-left: 40px;
            cursor: pointer;
            position: relative;
            i {
              width: 9px;
              height: 14px;
              line-height: 17px;
            }
            .code {
              position: absolute;
              top: 40px;
              left: -30px;
              width: 152px;
              height: 160px;
              background: url("../assets/images/dia.png") center no-repeat;
              background-size: cover;
              z-index: 9999;
              display: flex;
              align-items: center;
              img {
                width: 120px;
                height: 120px;
                margin: auto;
              }
            }
          }
        }
      }
    }
  }
  .header_body {
    width: 100%;
    height: 95px;
    background-color: #ffffff;
    .header_body_box {
      width: 1120px;
      display: flex;
      height: 95px;
      justify-content: space-between;
      align-items: center;
      margin: auto;
      img {
        width: auto;
        max-height: 56px;
        cursor: pointer;
      }
      .car {
        width: 151px;
        height: 32px;
        background: #f1f1f1;
        border: 1px solid #dbdbdb;
        padding: 5px 8px;
        display: flex;
        justify-content: space-between;
        position: relative;
        cursor: pointer;
        .icongouwuche1 {
          font-size: 15px;
          color: #e64747;
        }
        .title {
          width: 85px;
          height: 22px;
          font-size: 16px;
          font-weight: 400;
          color: #1a1a1a;
          line-height: 22px;
        }
        .latelyJoin {
          width: 318px;
          // height: 186px;
          background: #ffffff;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
          border: 1px solid #f6f6f6;
          position: absolute;
          top: 30px;
          left: -168px;
          padding: 12px;
          z-index: 4;
          .latelyJoinBox {
            width: 100%;
            height: 100%;
          }
          .latelyJoinBoxP {
            text-align: center;
          }
          .latelyJoinTitle {
            height: 20px;
            font-size: 14px;
            font-weight: 400;
            color: #363637;
            line-height: 20px;
          }
          .latelyJoinBoxMain {
            padding: 8px 0;
            border-bottom: 1px solid #ececec;
            display: flex;
            flex-wrap: nowrap;
            .latelyJoinBoxMainOne {
              width: 185px;
              display: flex;
              flex-wrap: nowrap;
              img {
                width: 40px;
                height: 40px;
                border-radius: 2px;
              }
              .latelyJoinBoxMainOneD {
                margin-left: 6px;
                width: 139px;
                height: 34px;
                font-size: 12px;
                font-weight: 400;
                color: #838383;
                line-height: 17px;
                display: -webkit-box;
                overflow: hidden;
                white-space: normal !important;
                text-overflow: ellipsis;
                word-wrap: break-word;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              }
            }
            .latelyJoinBoxMainTwo {
              width: 109px;
              .latelyJoinBoxMainTwoP1 {
                width: 100%;
                text-align: right;
                height: 20px;
                font-size: 14px;
                font-weight: 600;
                color: #1a1a1a;
                line-height: 20px;
              }
              .latelyJoinBoxMainTwoP2 {
                width: 30px;
                margin-left: 79px;
                text-align: right;
                height: 20px;
                font-size: 14px;
                font-weight: 400;
                color: #838383;
                line-height: 20px;
              }
              .latelyJoinBoxMainTwoP2:hover {
                color: red;
              }
            }
          }
          .latelyJoinFooter {
            display: flex;
            justify-content: space-between;
            margin-top: 8px;
            p {
              width: 84px;
              height: 28px;
              background: #e64747;
              border-radius: 2px;
              line-height: 28px;
              text-align: center;
              font-size: 14px;
              font-weight: 400;
              color: #ffffff;
            }
          }
        }
      }
      .car:hover {
        border: none;
        background: #ffffff;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
        border: 1px solid #f6f6f6;
        .title {
          color: red;
        }
      }
    }
  }
}
</style>