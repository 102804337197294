import axios from 'axios'
export default {
    // 上传
    upLoad: (data, cb) => {
        axios.post('api/attachment/upload',
            data,
            { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
        ).then(res => {
            cb && cb(res)
        })
    },
    //登录//---------------------------------------------------------------------------------------------------------------------------
    // 登录
    login: (data, cb) => {
        axios.get('user/login', {
            params: data
        }).then(res => {
            cb && cb(res)
        })
    },
    // 微信登录
    userWxlogin: (data, cb) => {
        axios.get('api/user/wxlogin', {
            params: data
        }).then(res => {
            cb && cb(res)
        })
    },
    // 验证码登录
    userloginMessage: (data, cb) => {
        axios.post('api/user/loginMessage', {
            ...data
        }).then(res => {
            cb && cb(res)
        })
    },
    // 发送验证码
    userSendMessage: (data, cb) => {
        axios.get('api/user/sendMessage', {
            params: data
        }).then(res => {
            cb && cb(res)
        })
    },
    //首页//---------------------------------------------------------------------------------------------------------------------------
    // 首页
    homePageMain: (data, cb) => {
        axios.get('taoru/homePage/main', {
            params: data
        }).then(res => {
            cb && cb(res)
        })
    },
    //猜你喜欢
    homePageYoulikeList: (data, cb) => {
        axios.post('taoru/homePage/youlike/list', {
            ...data
        }).then(res => {
            cb && cb(res)
        })
    },
    // 首页搜索
    homePagesearchHome: (data, cb) => {
        axios.post('taoru/homePage/searchHome', {
            ...data
        }).then(res => {
            cb && cb(res)
        })
    },
    // 好物排行
    homePageGoodList: (data, cb) => {
        axios.post('taoru/homePage/good/list', {
            ...data
        }).then(res => {
            cb && cb(res)
        })
    },
    // 精选热点
    homePageSelectList: (data, cb) => {
        axios.post('taoru/homePage/select/list', {
            ...data
        }).then(res => {
            cb && cb(res)
        })
    },
    // 金牌店铺
    homePageSearchHomeBus: (data, cb) => {
        axios.post('taoru/homePage/searchHomeBus', {
            ...data
        }).then(res => {
            cb && cb(res)
        })
    },
    // 地图找墓
    companyList: (data, cb) => {
        axios.post('/taoru/company/company/list', {
            ...data
        }).then(res => {
            cb && cb(res)
        })
    },
    // 静态首页详情
    taoruHomePageStaticDetail: (data, cb) => {
        axios.get('taoru/homePage/static/detail', {
            params: data
        }).then(res => {
            cb && cb(res)
        })
    },
    // 动态首页
    taoruHomePageDynamicList: (data, cb) => {
        axios.get('taoru/homePage/dynamic/list', {
            params: data
        }).then(res => {
            cb && cb(res)
        })
    },
    // 动态首页详情
    taoruHomePageDynamicDetail: (data, cb) => {
        axios.get('taoru/homePage/dynamic/detail', {
            params: data
        }).then(res => {
            cb && cb(res)
        })
    },
    //个人接口-------------------------------------------------------------------------------------------------------------------------------
    // 省市区
    companyLinkage: (data, cb) => {
        axios.get('taoru/company/linkage', {
            params: data
        }).then(res => {
            cb && cb(res)
        })
    },
    // 个人地址列表
    personalAddressList: (data, cb) => {
        axios.get('api/personal/address/list', {
            params: data
        }).then(res => {
            cb && cb(res)
        })
    },
    // 新增个人地址
    personalAddressAdd: (data, cb) => {
        axios.post('api/personal/address/add', {
            ...data
        }).then(res => {
            cb && cb(res)
        })
    },
    // 删除个人地址
    personalAddressDel: (data, cb) => {
        axios.get('api/personal/address/del', {
            params: data
        }).then(res => {
            cb && cb(res)
        })
    },
    // 修改个人地址
    personalAddressEdit: (data, cb) => {
        axios.post('api/personal/address/edit', {
            ...data
        }).then(res => {
            cb && cb(res)
        })
    },
    //积分接口-------------------------------------------------------------------------------------------------------------------------------
    // 积分预设
    pointPointPreset: (data, cb) => {
        axios.get('api/point/pointPreset', {
            params: data
        }).then(res => {
            cb && cb(res)
        })
    },
    // 积分充值
    pointPointRecharge: (data, cb) => {
        axios.post('api/point/pointRecharge', {
            ...data
        }).then(res => {
            cb && cb(res)
        })
    },
    //用户积分
    pointUserPointCount: (data, cb) => {
        axios.get('api/point/userPointCount', {
            params: data
        }).then(res => {
            cb && cb(res)
        })
    },
    // 积分记录-分页
    pointPointRecordPage: (data, cb) => {
        axios.post('api/point/pointRecordPage', {
            ...data
        }).then(res => {
            cb && cb(res)
        })
    },

    //订单接口-------------------------------------------------------------------------------------------------------------------------------
    // 订单列表
    orderOrderPage: (data, cb) => {
        axios.post('api/order/orderPage', {
            ...data
        }).then(res => {
            cb && cb(res)
        })
    },
    //订单删除
    orderDelOrder: (data, cb) => {
        axios.get('api/order/delOrder', {
            params: data
        }).then(res => {
            cb && cb(res)
        })
    },
    //提醒发货
    orderOrderDeliverRemind: (data, cb) => {
        axios.get('api/order/orderDeliverRemind', {
            params: data
        }).then(res => {
            cb && cb(res)
        })
    },
    //子订单详情
    orderSubOrderDetail: (data, cb) => {
        axios.get('api/order/subOrderDetail', {
            params: data
        }).then(res => {
            cb && cb(res)
        })
    },
    //订单运费
    orderFreightAmt: (data, cb) => {
        axios.post('api/order/freightAmt', {
            ...data
        }).then(res => {
            cb && cb(res)
        })
    },
    //获取支付二维码
    orderPayOrder: (data, cb) => {
        axios.get('api/order/payOrder', {
            params: data
        }).then(res => {
            cb && cb(res)
        })
    },
    //确认发货
    orderConfirmDeliver: (data, cb) => {
        axios.get('api/order/confirmDeliver', {
            params: data
        }).then(res => {
            cb && cb(res)
        })
    },
    orderRefund: (data, cb) => {
        axios.post('api/order/refund', {
            ...data
        }).then(res => {
            cb && cb(res)
        })
    },
    //收藏夹接口-------------------------------------------------------------------------------------------------------------------------------
    
    personalFavoritesList: (data, cb) => {
        axios.post('api/personal/favorites/list', {
            ...data
        }).then(res => {
            cb && cb(res)
        })
    },
    //收藏夹添加
    personalFavoritesAdd: (data, cb) => {
        axios.get('api/personal/favorites/add', {
            params: data
        }).then(res => {
            cb && cb(res)
        })
    },
    //收藏夹删除
    personalFavoritesDel: (data, cb) => {
        axios.get('api/personal/favorites/del', {
            params: data
        }).then(res => {
            cb && cb(res)
        })
    },
    //收藏夹删除sku
    personalFavoritesDelBySku: (data, cb) => {
        axios.get('api/personal/favorites/delBySku', {
            params: data
        }).then(res => {
            cb && cb(res)
        })
    },
    //收藏夹数量
    personalFavoritesCount: (data, cb) => {
        axios.get('api/personal/favorites/count', {
            params: data
        }).then(res => {
            cb && cb(res)
        })
    },
    //购物车接口-------------------------------------------------------------------------------------------------------------------------------
    shoppingCartShoppingCartGoodsList: (data, cb) => {
        axios.get('api/shoppingCart/shoppingCartGoodsList', {
            params: data
        }).then(res => {
            cb && cb(res)
        })
    },
    // 购物车加入
    shoppingCartAddShoppingCartGoods: (data, cb) => {
        axios.post('api/shoppingCart/addShoppingCartGoods', {
            ...data
        }).then(res => {
            cb && cb(res)
        })
    },
    // 购物车删除
    shoppingCartDelShoppingCartGoods: (data, cb) => {
        axios.post('api/shoppingCart/delShoppingCartGoods', {
            ...data
        }).then(res => {
            cb && cb(res)
        })
    },
    // 购物车修改
    shoppingCartEditShoppingCartGoods: (data, cb) => {
        axios.post('api/shoppingCart/editShoppingCartGoods', {
            ...data
        }).then(res => {
            cb && cb(res)
        })
    },

    //公益活动-------------------------------------------------------------------------------------------------------------------------------
    //公益活动详情
    publicaAtiivitiesActiivitiesDetail: (data, cb) => {
        axios.get('taoru/public/actiivities/actiivitiesDetail', {
            params: data
        }).then(res => {
            cb && cb(res)
        })
    },
    // 公益活动-分页
    publicaAtiivitiesActiivitiesPage: (data, cb) => {
        axios.post('taoru/public/actiivities/actiivitiesPage', {
            ...data
        }).then(res => {
            cb && cb(res)
        })
    },
    // 公益活动-课程
    courseList: (data, cb) => {
        axios.post('taoru/public/actiivities/course/list', {
            ...data
        }).then(res => {
            cb && cb(res)
        })
    },
    //评价//---------------------------------------------------------------------------------------------------------------------------
    orderComment: (data, cb) => {
        axios.post('api/order/comment', {
            ...data
        }).then(res => {
            cb && cb(res)
        })
    },
    //订单接口-------------------------------------------------------------------------------------------------------------------------------
    // 积分商品-分页
    pointPointGoodsPage: (data, cb) => {
        axios.post('api/point/pointGoodsPage', {
            ...data
        }).then(res => {
            cb && cb(res)
        })
    },
    //商品-------------------------------------------------------------------------------------------------------------------------------------
    shopAllGoods: (data, cb) => {
        axios.post('taoru/shop/allGoods', {
            ...data
        }).then(res => {
            cb && cb(res)
        })
    },
    //合作陵园-------------------------------------------------------------------------------------------------------------------------------------
    homePageCompanyList: (data, cb) => {
        axios.post('taoru/homePage/company/list', {
            ...data
        }).then(res => {
            cb && cb(res)
        })
    },
    //陵园首页
    companyCompanyHome: (data, cb) => {
        axios.get('taoru/company/companyHome', {
            params: data
        }).then(res => {
            cb && cb(res)
        })
    },
    //园区详情
    companyCemeteryDetail: (data, cb) => {
        axios.get('taoru/company/cemeteryDetail', {
            params: data
        }).then(res => {
            cb && cb(res)
        })
    },
    //陵园园区
    companyCompanyCemetery: (data, cb) => {
        axios.get('taoru/company/companyCemetery', {
            params: data
        }).then(res => {
            cb && cb(res)
        })
    },
    //礼仪服务
    homePageEtiquetteServiceDetail: (data, cb) => {
        axios.get('taoru/homePage/etiquetteService/detail', {
            params: data
        }).then(res => {
            cb && cb(res)
        })
    },
    //专区商品
    taoruShopArrondiGoodsByName: (data, cb) => {
        axios.post('taoru/shop/arrondiGoodsByName', {
            ...data
        }).then(res => {
            cb && cb(res)
        })
    },
    //骨灰寄存套餐
    apiOrderAshesMealList: (data, cb) => {
        axios.get('api/order/ashesMeal/list', {
            params: data
        }).then(res => {
            cb && cb(res)
        })
    },
    //骨灰寄存订单提交
    apiOrderAshesMealSubmit: (data, cb) => {
        axios.post('api/order/ashesMeal/submit', {
            ...data
        }).then(res => {
            cb && cb(res)
        })
    },
    //殡葬礼仪套餐
    apiOrderFuneralEtiquetteList: (data, cb) => {
        axios.get('api/order/funeralEtiquette/list', {
            params: data
        }).then(res => {
            cb && cb(res)
        })
    },
    //殡葬礼仪提交
    apiOrderFuneralEtiquetteAdd: (data, cb) => {
        axios.post('api/order/funeralEtiquette/add', {
            ...data
        }).then(res => {
            cb && cb(res)
        })
    },
    //墓碑维修
    repairTombAdd: (data, cb) => {
        axios.post('api/order/repairTomb/add', {
            ...data
        }).then(res => {
            cb && cb(res)
        })
    },
    //添加投诉
    complaintAdd: (data, cb) => {
        axios.post('api/order/complaint/add',{
            ...data
        }).then(res => {
            cb && cb(res)
        })
    },
     //获取陵园商户信息
     companyDetail: (data, cb) => {
        axios.get('taoru/company/detail', {
            params: data
        }).then(res => {
            cb && cb(res)
        })
    },
    //创建订单-------------------------------------------------------------------------------------------------------------------------------------
    orderCreateOrder: (data, cb) => {
        axios.post('api/order/createOrder', {
            ...data
        }).then(res => {
            cb && cb(res)
        })
    },
    //商品详情-------------------------------------------------------------------------------------------------------------------------------------
    shopGoodsDetail: (data, cb) => {
        axios.get('taoru/shop/goodsDetail', {
            params: data
        }).then(res => {
            cb && cb(res)
        })
    },
    //商品分类
    taoruShopGoodsCategory: (data, cb) => {
        axios.get('taoru/shop/goodsCategory', {
            params: data
        }).then(res => {
            cb && cb(res)
        })
    },
    //店铺商品分类
    taoruShopCategoryAllList: (data, cb) => {
        axios.get('taoru/shop/categoryAllList', {
            params: data
        }).then(res => {
            cb && cb(res)
        })
    },
    //商户详情
    taoruBusinessDetail: (data, cb) => {
        axios.get('taoru/business/detail', {
            params: data
        }).then(res => {
            cb && cb(res)
        })
    },
    //好物排行
    shopGoodSale: (data, cb) => {
        axios.post('taoru/shop/good/sale', {
            ...data
        }).then(res => {
            cb && cb(res)
        })
    },
    //热卖商品前三
    businessGoodsHotList: (data, cb) => {
        axios.get('taoru/business/goodsHotList', {
            params: data
        }).then(res => {
            cb && cb(res)
        })
    },
    //最新商品
    businessGoodsNewList: (data, cb) => {
        axios.get('taoru/business/goodsNewList', {
            params: data
        }).then(res => {
            cb && cb(res)
        })
    },
    //查询所有商户类型
    businessCategoryAllList: (data, cb) => {     //businessType
        axios.get('taoru/business/categoryAllList', {
            params: data
        }).then(res => {
            cb && cb(res)
        })
    },
    //订单状态-------------------------------------------------------------------------------------------------------------------------------------
    orderNativePayStatus: (data, cb) => {
        axios.get('api/order/nativePayStatus', {
            params: data
        }).then(res => {
            cb && cb(res)
        })
    },
    //积分接口-------------------------------------------------------------------------------------------------------------------------------------
    pointPointGoodsDetail: (data, cb) => {
        axios.get('api/point/pointGoodsDetail', {
            params: data
        }).then(res => {
            cb && cb(res)
        })
    },
    pointBuyPointGoods: (data, cb) => {
        axios.get('api/point/buyPointGoods', {
            params: data
        }).then(res => {
            cb && cb(res)
        })
    },
    //热搜词-------------------------------------------------------------------------------------------------------------------------------------
    hotWordsHotWordsPage: (data, cb) => {
        axios.post('taoru/hotWords/hotWordsPage', {
            ...data
        }).then(res => {
            cb && cb(res)
        })
    },
    //站点管理-------------------------------------------------------------------------------------------------------------------------------------
    subscribestationList: (data, cb) => {
        axios.get('taoru/subscribestation/list', {
            params: data
        }).then(res => {
            cb && cb(res)
        })
    },
    //预约看墓-------------------------------------------------------------------------------------------------------------------------------------
    subscribeTombDelete: (data, cb) => {
        axios.get('api/subscribeTomb/delete', {
            params: data
        }).then(res => {
            cb && cb(res)
        })
    },
    subscribeTombDetail: (data, cb) => {
        axios.get('api/subscribeTomb/detail', {
            params: data
        }).then(res => {
            cb && cb(res)
        })
    },
    subscribeTombAdd: (data, cb) => {
        axios.post('api/subscribeTomb/add', {
            ...data
        }).then(res => {
            cb && cb(res)
        })
    },
    subscribeTombList: (data, cb) => {
        axios.post('api/subscribeTomb/list', {
            ...data
        }).then(res => {
            cb && cb(res)
        })
    },
    subscribeTombModify: (data, cb) => {
        axios.post('api/subscribeTomb/modify', {
            ...data
        }).then(res => {
            cb && cb(res)
        })
    },
    homePageinsertIP: (data, cb) => {
        axios.get('/taoru/homePage/insertIP', {
            params: data
        }).then(res => {
            cb && cb(res)
        })
    },
    subscribeTombPayOrder: (data, cb) => {
        axios.get('api/subscribeTomb/payOrder', {
            params: data
        }).then(res => {
            cb && cb(res)
        })
    },
    subscribeTombPackagelist: (data, cb) => {
        axios.post('api/subscribeTomb/packagelist', {
            ...data
        }).then(res => {
            cb && cb(res)
        })
    },
    //关注
    businessUserAdd: (data, cb) => {
        axios.get('api/businessUser/add', {
            params: data
        }).then(res => {
            cb && cb(res)
        })
    },
    businessUserDel: (data, cb) => {
        axios.get('api/businessUser/del', {
            params: data
        }).then(res => {
            cb && cb(res)
        })
    },
    businessUserDetail: (data, cb) => {
        axios.get('api/businessUser/detail', {
            params: data
        }).then(res => {
            cb && cb(res)
        })
    },
    businessUserList: (data, cb) => {
        axios.post('api/businessUser/list', {
            ...data
        }).then(res => {
            cb && cb(res)
        })
    },
}